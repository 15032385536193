@import "src/styles/variables";

.vendor-form-section-container {
  display: flex;
  flex-direction: column;
  padding: 10px;

  .form-container-sec {
    display: flex;
    .form-each-section {
      margin-right: 25px;
      width: 33.33%;

      .form-each-section-label {
        color: $form-label-section;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        letter-spacing: 0.12em;
        line-height: 24px;
        text-transform: uppercase;
        margin-bottom: 12px;
      }

      .file-input-box {
        margin-top: 11px !important;
        .file-input {
          height: 16px;
        }
      }

      .double-box-each-section {
        width: 49%;
      }

      .mar-bottom {
        margin-bottom: 27px;
      }

      .double-box {
        display: flex;
        justify-content: space-between;
      }
      .checkbox-postion {
        //margin-top: 10px;
        margin-left: -10px;
        color: rgba(0, 0, 0, 0.6);
      }
    }
  }

  .save-button {
    display: flex;
    justify-content: flex-end;
    padding: 20px;
  }
}
