@import "/src/styles/variables";

.popper-container {
  position: absolute;
  z-index: 100;
  right: 0;
  background-color: $white;
  border-radius: 10px;
  margin-top: 3px;
  box-shadow: $popper-shadow-color;
  margin-right: 0.5rem;
  transition: 0.5s;
  transition: opacity 297ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    transform 198ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  backdrop-filter: blur(20px);
  background-image: url(https://minimals.cc/assets/cyan-blur.png),
    url(https://minimals.cc/assets/red-blur.png);
  background-repeat: no-repeat, no-repeat;
  background-position: right top, left bottom;
  background-size: 50%, 50%;
  opacity: 1;
  transition: opacity 297ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    transform 198ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  ::before {
    content: "";
    position: absolute;
    top: -6px;
    right: 15px;
    width: 12px;
    height: 15px;
    background-color: $bg-color;
    transform: rotate(45deg);
  }
}
