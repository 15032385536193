@import "src/styles/variables";

.file-upload-container {
  .dropzone {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 100px;
    width: auto;
    border: 1px dashed $medium-grey;
    padding: 10px;
  }

  .drag-active {
    border: 4px dashed $top-header-color !important;
  }

  .read-only-filepicker {
    pointer-events: none;
    opacity: 0.2;
  }

  .dropzone-section {
    color: inherit;
    background-color: inherit;
    border-color: inherit;
    border-width: 1px;
    border-radius: 4px;
    cursor: pointer;
  }
}
