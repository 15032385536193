@import "src/styles/variables";

.date-box-container {
  display: flex;
  flex-direction: column;
  margin-top: 12px;

  .base-css {
    height: 25px;
    width: 88%;
    font-size: 14px;
    // border-color: inherit !important;
    border-width: 0.3px;
    padding-left: 30px;
    border-radius: 4px;
    border: $border;
  }

  .react-datepicker__input-container .react-datepicker__calendar-icon {
    position: absolute;
    padding: 0.7rem;
    box-sizing: content-box;
  }

  .label-style {
    color: $input-label;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
  }
  .mandatory-field {
    color: $color-red;
    font-size: 16px;
    position: absolute;
    margin-left: 4px;
  }
}
