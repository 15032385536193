@import "src/styles/variables";

.loading-overlay-container {
  display: flex;
  background: $fade-white;
  position: fixed;
  width: 100%;
  left: 0;
  height: 100vh;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  .loading-content {
    // .loader-sec {
    //   margin-left: 60px;
    // }
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
