@import "../../styles/variables";

.search-container {
  background-color: $white;
  position: relative;
  padding-left: 16px;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 4px;
  border: 1px solid $light-gray;

  .css-1le28he-MuiInputBase-root {
    font-family: "Poppins";
  }

  .search-icon-wrapper {
    height: 100%;
    position: absolute;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 600px) {
    .search-icon-wrapper {
      display: none !important;
    }
  }

  .styled-input-base {
    color: inherit;
    font-size: 14px;
    & .MuiInputBase-input {
      padding-left: calc(1em + 32px);
      transition: width 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      width: 100%;
      @media (min-width: 960px) {
        width: 250px;
      }
      @media (max-width: 600px) {
        padding-left: 0;
      }
    }
  }
}
