@import "src/styles/variables";

.top-header-container {
  background-color: $dashboard-header-color;
  height: 75px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 99%;
  width: -webkit-fill-available;
  top: 0;
  z-index: 3;
  //padding-bottom: 10px;
  padding-left: 25px;

  .top-left-section {
    display: flex;
    align-items: center;
    .logo-sec {
      padding-right: 20px;
    }
    .header-text {
      color: $white;
    }
  }
  .top-header-right {
    padding-left: 0.5rem;
    display: flex;
    align-items: center;
    margin-right: 16px;

    .icon-container {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: $top-profile-icon-color;
      font-size: 25px;
      color: $white;
      display: grid;
      align-items: center;
      text-align: center;
      letter-spacing: 1px;
      margin-left: 1rem;
      border: 3px solid;
      cursor: pointer;
      overflow: hidden;
      .profile-img {
        width: 40px;
        height: 40px;
        object-fit: cover;
      }
    }
    .icon-container:hover {
      transform: scale(1.05) translateZ(0px);
    }
    .active-color {
      background-color: $top-header-color;
    }
    .top-header-name {
      width: 120px; /* Adjust this width as needed */
      display: flex;
      align-items: center;
      overflow: hidden;
      .text-header {
        width: 200px; /* Set your desired width */
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 0.5rem;
        margin-left: 20px;
        font-size: 15px;
        font-weight: 500;
        align-items: center;
        margin-top: 22px;
        color: $sidebar-color;
      }
    }
    .user-popper {
      margin-right: 1rem;
      margin-top: 18rem;
      width: 200px;
    }
  }
}
