@import "src/styles/variables";
@import "src/styles/mixin";

.sup-admin-dash-css {
  margin: 20px;
}
.invoicing-container {
  margin: 16px;
  margin-left: 32px;

  .section-label {
    @include section-header-lebel;
    font-size: 24px;
  }

  .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    height: 65px !important;
    color: $table-header !important;
    letter-spacing: 0.034em;
    font-family: $mui-font-family;
  }

  .top-button-section {
    background-color: $white;
    height: 60px;
    width: 100%;
    border: $border;
    border-radius: 5px;
    .css-1q2h7u5 {
      min-height: 60px !important;
    }
  }

  .filter-set-section {
    margin-top: 20px;
    background-color: $white;
    border: $border;
    border-radius: 5px;
    height: 100px;
    display: flex;
    padding-left: 25px;
    align-items: center;

    .date-box {
      margin-bottom: 25px;
    }

    .mar-8 {
      margin-top: 8px;
    }

    .field-sec {
      margin-left: 40px;
    }
  }

  .grid-section {
    margin-top: 20px;
    background-color: $white;
    border: $border;
    border-radius: 5px;

    .checkbox-postion-dash {
      display: flex;
      align-items: center;
      color: $text-color-light;

      .checkbox-sec {
        color: $text-color-light;
      }
    }

    .search-input {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px;

      .search-field {
        width: 20%;
      }

      .invoice-icon {
        padding-right: 5px;
      }

      .mar-10 {
        margin-bottom: 10px;
        width: 20%;
      }
    }

    .show-more-btn {
      background-color: transparent !important;
    }
  }
}

.display-more-component {
  display: flex;
  .each-data {
    display: flex;
  }
}

.assign-modal {
  .assign-modal-container {
    display: flex;
    flex-direction: column;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-bottom: 2rem;

    .dropdown-section-form {
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;

      .field-wid {
        width: 48%;
      }
    }
    .port-field {
      width: 48% !important;
    }
  }
  .bottom-container {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    padding-bottom: 1rem;
    padding-right: 1rem;
    padding-top: 10px;
  }
}
