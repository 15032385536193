@import "src/styles/variables";
@import "src/styles/mixin";

.local-containers {
  padding-left: 50px;
}

.reg-request-container {
  margin: 16px;
  margin-left: 32px;

  .approve-button {
    background-color: green !important;
  }

  .section-label {
    @include section-header-lebel;
    font-size: 24px;
  }
  .search-box {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;

    .export-icon{
      font-size: 19px;
      margin-left: 8px;
    }
  }

  .action-btn-container {
    padding-left: 10px;
    padding-right: 12px;
    padding-top: 2px;
    padding-bottom: 4px;
    background: #667489;
    width: min-content;
    border-radius: 4px;
    color: white;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }
}

.partner-modal {
  .partner-modal-container {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
  }
  .bottom-container {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    padding-bottom: 1rem;
    padding-right: 1rem;
    padding-top: 10px;
  }
}
