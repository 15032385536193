@import "src/styles/variables";

.css-tzssek-MuiSvgIcon-root {
  color: $button-color !important;
}

.custom-grid-container {
  border-radius: 3px;
  overflow: hidden;

  .pagination-section {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background-color: $grid-header-color;
    // box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    //   rgba(0, 0, 0, 0.22) 0px 10px 10px;
    height: 44px;
    margin-top: 25px;

    .css-10w330c-MuiButtonBase-root-MuiPaginationItem-root {
      color: $white !important;
      font-family: $mui-font-family !important;
    }

    .css-10w330c-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
      background-color: $button-color;
    }

    .css-1v2lvtn-MuiPaginationItem-root {
      color: $white !important;
      font-family: $mui-font-family !important;
    }

    .page-size-field {
      width: 40%;
      height: 20px;
    }

    .page-text {
      font-weight: 800;
      font-size: 14px;
      margin-left: 10px;
    }

    // .page-count-size {
    //   color: #fff !important;
    // }
  }

  table {
    border: 1px solid #ccc;
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 100%;
    table-layout: fixed;
  }

  table caption {
    font-size: 1.5em;
    margin: 0.5em 0 0.75em;
  }

  table tr {
    //background-color: #f8f8f8;
    border: 1px solid $light-gray;
    padding: 0.35em;
    background-color: $white;
  }

  table th,
  table td {
    //padding: 0.625em;
    padding-top: 9px;
    padding-bottom: 9px;
    text-align: center;
    font-size: 14px;
    color: $grid-table-color;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  table th {
    padding-top: 15px;
    padding-bottom: 15px;
    // font-size: 0.85em;
    letter-spacing: 0.1em;
    // text-transform: uppercase;
    background-color: $grid-header-color;
    color: #fff;
    font-size: 14px;
    font-weight: 800;
  }
  .data-text {
    font-size: 16px;
    font-weight: bold;
  }
  .sort-icon {
    transition: transform 0.3s ease;
    font-size: 13px;
    margin-left: 3px;
  }
  .rotate {
    transform: rotate(0deg);
  }

  .rotate180 {
    transform: rotate(180deg);
  }
  @media screen and (max-width: 600px) {
    table {
      border: 0;
    }

    table caption {
      font-size: 1.3em;
    }

    table thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }

    table tr {
      border-bottom: 3px solid #ddd;
      display: block;
      margin-bottom: 0.625em;
    }

    table td {
      border-bottom: 1px solid #ddd;
      display: block;
      font-size: 0.8em;
      text-align: right;
    }

    table td::before {
      /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
      content: attr(data-label);
      float: left;
      font-weight: bold;
      text-transform: uppercase;
    }

    table td:last-child {
      border-bottom: 0;
    }
  }

  /* general styling */
}
