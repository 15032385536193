@import "/src/styles/variables";

.user-profile-container {
  .name-email {
    padding: 10px 16px 12px;
    .user-name {
      font-weight: 600;
      line-height: 1.8;
      font-size: 0.875rem;
      margin: 0px;
      margin-top: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .user-email {
      margin: 0px;
      line-height: 1.57143;
      font-size: 0.785rem;
      font-weight: 400;
      overflow: hidden;
      text-overflow: ellipsis;
      color: $sidebar-text-color;
    }
  }
  .popper-hr-line {
    margin: 0px;
    border-width: 0px 0px thin;
    border-color: $sidebar-border-color;
    border-style: dashed;
  }
  .user-profile-opt {
    display: flex;
    flex-direction: column;
    padding: 8px;
    .user-opt {
      cursor: pointer;
      display: flex;
      line-height: 1.57143;
      font-size: 0.875rem;
      font-weight: 400;
      padding: 6px 8px;
      border-radius: 6px;
      justify-content: flex-start;
      align-items: center;
    }
    .user-opt:hover {
      background-color: $banner-color;
      color: $white;
    }
    .user-opt:not(:last-of-type) {
      margin-bottom: 4px;
    }
  }

  .logout {
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    line-height: 1.57143;
    font-size: 0.875rem;
    padding: 6px 8px;
    border-radius: 6px;
    margin: 0.6rem;
    font-weight: 700;
    text-decoration: none;
    color: $color-smooth-red;
  }

  .logout:hover {
    background-color: $banner-color;
    color: $white;
  }
}
