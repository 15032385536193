@import "src/styles/variables";

input[data-type="password"] {
  padding: 9.5px 14px;
}

input[data-type="text"] {
  padding: 9.5px 14px;
}
.input-box-container {
  .mg-input-box {
    background-color: white;
    .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root {
      font-family: "Nunito Sans", sans-serif !important;
    }

    // .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    //   background-color: $sidebar-color;
    // }

    .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
      font-family: "Nunito Sans", sans-serif !important;
    }

    .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
      font-family: "Nunito Sans", sans-serif !important;
    }

    .css-jh47zj-MuiButtonBase-root-MuiButton-root {
      font-family: "Nunito Sans", sans-serif !important;
    }

    .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
      height: 0.4375em !important;
    }

    .css-1x5jdmq {
      height: 0.4375em !important;
    }

    /* Error Message Inputbox */
    .css-1wc848c-MuiFormHelperText-root {
      margin-left: 1px;
      font-family: "Nunito Sans", sans-serif;
    }

    // .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
    //   color: $font-color;
    //   border-color: black;
    // }F
    // .css-13cymwt-control {
    //   min-height: 41px;
    //   border-radius: 6px;
    // }
    // .css-t3ipsp-control {
    //   min-height: 41px;
    // }
  }
  .phone-number-input-text {
    width: 100%;
  }

  display: flex;
  flex-direction: column;
  margin-top: 12px;

  .label-style {
    color: $input-label;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
  }
  .mandatory-field {
    color: $color-red;
    font-size: 16px;
    position: absolute;
    margin-left: 4px;
  }
}
