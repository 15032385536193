@import "src/styles/variables";

.button-container {
  text-transform: capitalize !important;
  font-family: $mui-font-family !important;
  background-color: $button-all-color !important;
}

// .css-sghohy-MuiButtonBase-root-MuiButton-root {
//   font-family: $mui-font-family !important;
//   background-color: $button-color !important;
// }

// ..css-jh47zj-MuiButtonBase-root-MuiButton-root

button[class*="MuiButton-outlined"] {
  font-family: $mui-font-family !important;
  border-color: $top-header-color !important;
  color: $top-header-color !important;
  background-color: $white !important;
}

button:disabled,
button[disabled] {
  border: 1px solid $disable-button-border !important;
  background-color: $disable-button !important;
  color: $disable-button-text-color !important;
}

.submit-button{
  background-color: $button-all-color !important;
}
.model-button{
  background-color: $button-all-color !important;
}

.save-btn{
  background-color: $button-all-color !important;
}