@import "src/styles/variables";

.main-layout-container {
  background-color: $white;
  height: 100vh;

  .home {
    position: absolute;
    top: 90px;
    left: 250px;
    width: calc(100% - 250px);
    background-color: $white;
    transition: $tran-05;
  }

  .without-left-navbar {
    width: 100%;
    position: absolute;
    left: 0;
    top: 90px;
  }
}
