//global
$font-color: #1d1d1d;
$app-background: #e6e6e6;
$horizontal-line: #f3f3f77f;
$white: #ffffffff;
$light-gray: #ccc;
$left-navbar-icon-color: #2f80ed;
$light-gray-color: #e9e9e9;
$main-sec-background: #f0f8ff;

//custom
$color-red: #dc1025;
$login-text-color: #dc1225;
$grid-table-color: #202020;

$base-button-color: #10559a;
$fade-white: rgba(255, 255, 255, 0.5);
$button-bg-color: #0f559a;
// Sidebar
/* ===== Colors ===== */
$strong-blue: #1976d2;
$primary-color: #2c2c2c;
$primary-color-light: #f6f5ff;
$toggle-color: #ddd;
$text-color: #0d0138;
$user-color: #3105cf;
$popper-shadow-color: rgba(0, 0, 0, 0.15) 0px 2px 8px;
$sidebar-text-color: rgb(99, 115, 129);
$hover-background: rgba(99, 115, 129, 0.08);
$color-smooth-red: rgb(255, 86, 48);
$place-holder-color: #b5b5b5;
$bg-color: #f5fbfd;
// general blue button
$disable-button-border: #999999;
$disable-button: #cccccc;
$disable-button-text-color: #666666;
//Header color scss : Please refer for colors in Header
$dark-grey: #333333;
$ash: #f4f4f4;
$white: white;
$header-accordion-btn: #f7fafe;
$shadow:
  rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
  rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  // Text-color
  $text-color-light: rgba(0, 0, 0, 0.6);
// Table
$table-header: #000000;
$title-color: #a8a8b1;
$notification-background: #fc5a5a;
$login-page-text: #777;
// Box-shadow-color
$box-shadow-color: rgba(0, 0, 0, 0.12);

/* ====== Transition ====== */
$tran-03: all 0.2s ease;
$tran-03: all 0.3s ease;
$tran-04: all 0.3s ease;
$tran-05: all 0.5s ease;

// material ui font family
$mui-font-family: "Nunito Sans", sans-serif;

// Base spacing unit (minimum spacing value)
$spacing-base: 30px;

// Use this small spacing for tight spaces between elements like icons, small buttons, or compact form elements.
$spacing-small: $spacing-base * 1;
/// New
///
$medium-grey: #636363;
$light-grey: #eeeeee;
$border: 1.2px solid #d3dbe8;
$yale-blue-color: #10559a;
$button-color: #0066cc;
$green-color: #25b45b;


///
$card-select-color: rgba(0, 149, 128, 0.47);
$card-border: #009580;
$sidebar-border-color: #d3dbe8;
$sidebar-color: $white;
$top-header-color: #424245;
$sidebar-option-background: $yale-blue-color;
$grid-header-color: #424245;
$user-menu-color: $yale-blue-color;
$profile-border: $border;
$sidebar-group-text-color: rgba(0, 0, 0, 0.6);
$form-label-section: #718096;
$input-label: #2d3748;
$dashboard-color: #f56300;
$dashboard-header-color: #0f5499;
$processing-banner-color: #db1226;
$button-all-color: #218380;
$banner-color: #c90754;
$top-profile-icon-color: #107a94;
// material ui font family
