@import "src/styles/variables";
@import "src/styles/mixin";

.sup-admin-dash-css {
  margin: 20px;
}
.invoicing-details-container {
  margin: 16px;
  margin-left: 32px;

  .generate-invoice-toolbar {
    display: flex;
    justify-content: space-between;
    padding: 15px;

    .dollar-icon {
      padding-right: 5px;
      font-size: 20px;
    }

    .search-field {
      width: 17%;
    }
  }
  // .top-invoice-detail-name {
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  // }

  // .address-section {
  //   display: flex;
  //   justify-content: space-between;
  //   .address-one {
  //   }
  //   .address-two {
  //   }
  //   .grid-one {
  //     width: 40%;
  //   }
  //   .grid-two {
  //     width: 60%;
  //   }
  // }
  // .meal-info {
  //   display: flex;
  //   justify-content: space-between;
  //   padding-top: 15px;
  //   padding-bottom: 15px;
  //   letter-spacing: 0.1em;
  //   background-color: #424245;
  //   color: #fff;
  //   font-size: 14px;
  //   font-weight: 800;
  //   width: 100%;
  //   align-items: center;
  //   .first-info {
  //     padding-left: 10px;
  //   }
  //   .sec-info {
  //     padding-right: 10px;
  //     .mar-l-5 {
  //       padding-right: 10px;
  //     }
  //   }
  //   .total-info {
  //     padding-right: 10px;
  //   }
  // }
  // .button-container-area {
  //   display: flex;
  //   justify-content: flex-end;
  //   margin-top: 20px;
  //   .mar-8 {
  //     margin-left: 20px;
  //   }
  // }
  // .invoice-details-box {
  //   flex-direction: column;
  //   border: thin solid $box-shadow-color;
  //   border-radius: 5px;
  //   padding: 10px;
  //   margin-top: 15px;

  //   .header-section-label {
  //     display: flex;
  //     justify-content: space-between;
  //     padding-left: 1%;
  //     .show-more-btn {
  //       background-color: transparent !important;
  //     }
  //     .contact-person-name {
  //       color: $form-label-section;
  //       font-size: 14px;
  //       font-style: normal;
  //       font-weight: 700;
  //       letter-spacing: 0.12em;
  //       line-height: 24px;
  //       text-transform: uppercase;
  //     }
  //     .trash-button-css {
  //       cursor: pointer;
  //       color: $color-red;
  //       padding-top: 6px;
  //     }
  //   }

  //   .form-sec-details {
  //     display: flex;
  //     .form-field-width {
  //       width: 19%;
  //       padding-left: 1%;
  //     }
  //   }
  // }
}
