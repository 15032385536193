@import "src/styles/variables";
@import "src/styles/mixin";

.modal-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40%;
  background-color: $white;
  border-radius: 8px;
  box-shadow: $shadow;

  .modal-title {
    @include section-header-lebel;
    padding: 1.5rem;
    padding-bottom: 1rem;
    .title {
      margin: 0;
    }
  }

  @media (max-width: 912px) {
    width: 70%;
  }

  @media (max-width: 480px) {
    width: 80%;
  }
}
