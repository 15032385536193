@import "./variables";

@mixin section-header-lebel {
  color: #424245;
  font-style: normal;
  font-weight: 700;
}

@mixin link-style {
  color: $yale-blue-color !important;
  text-decoration: unset;
  text-align: center;
  &:hover {
    text-decoration: underline;
  }
}
