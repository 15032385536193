@import "src/styles/variables";

.list-view {
  height: 50vh;
  overflow-y: scroll;
}

::-webkit-scrollbar {
  display: none;
}

.cross-button {
  padding-right: 20px;
  color: $color-red;
}

.big-size-view {
  color: $light-grey;
}
.view-img {
  width: 100%;
  height: 34vh;
  object-fit: contain;
  border-radius: 10px;
}
