@import "src/styles/variables";

.select-box-container {
  display: flex;
  flex-direction: column;
  margin-top: 12px;

  .label-style {
    color: $input-label;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
  }
  .mandatory-field {
    color: $color-red;
    font-size: 16px;
    position: absolute;
    margin-left: 4px;
  }
}
