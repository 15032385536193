@import "src/styles/variables";

.card-container {
  flex: 20 0 400px;
  height: 100px;
  display: flex;
  justify-content: left;
  align-items: center;
  width: 400px;
  max-width: 350px;
  border-radius: 10px;
  background-color: $white;
  box-shadow: 2px 2px 29px 11px hsla(240, 5%, 41%, 0.2);
  cursor: pointer;
  position: relative;
  overflow: hidden;
  &::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    border-radius: 50%;
    padding: 50%;
    width: 32px;
    height: 32px;
    background-color: rgb(var(--pure-material-onprimary-rgb, 255, 255, 255));
    opacity: 0;
    transform: translate(-50%, -50%) scale(1);
    transition: opacity 1s, transform 0.5s;
  }
  &:active::after {
    opacity: 0.32;
    transform: translate(-50%, -50%) scale(0);
    transition: transform 0s;
  }
  &:hover {
    //border: 1px solid $top-header-color !important;
    background-color: $card-select-color;
  }

  .card-title {
    //padding: 20px;
    // padding-top: 20px;
    // text-align: center;
    color: $top-header-color;
    font-size: 18px;
    font-weight: 700;
  }

  .card-image {
    padding: 20px;
    padding-top: 25px;
    text-align: center;
    font-weight: 700;
    font-size: 40px;
  }

  .card-service {
    padding-top: 15px;
    text-align: center;
  }

  .card-description {
    padding-top: 15px;
    text-align: center;
  }

  .card-footer {
    text-align: center;
    padding-top: 30px;
  }
}
.active-card {
  background-color: $card-select-color;
  box-shadow: 0px 4px 4px 0px $box-shadow-color;
  border: 1px solid $card-border;
}
