@import "src/styles/variables";

.transfer-employee-information-container {
  .mar-10 {
    margin-top: 10px;
  }
  display: flex;
  flex-direction: column;
  padding: 10px;
  .top-header-info {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    .left-sec {
      padding-left: 20px;
    }
    .section-label {
      color: $form-label-section;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      letter-spacing: 0.12em;
      line-height: 24px;
      text-transform: uppercase;
      padding-top: 8px;
    }
  }

  .save-button {
    display: flex;
    justify-content: flex-end;
    padding: 20px;
  }

  .top-information-title {
    display: flex;
    width: 100%;
    height: 100px;
    border: 1px solid #eeeeee;
    justify-content: space-between;
    align-items: center;

    .right-sec {
      padding-right: 20px;
    }
  }
}

.employee-modal {
  width: 60%;
  .employee-form-box {
    display: flex;
    flex-direction: column;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-bottom: 2rem;

    .form-sec-details {
      display: flex;

      .form-field-width {
        width: 32%;
        padding-left: 10px;
      }
    }
  }
  .bottom-container {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    padding-bottom: 1rem;
    padding-right: 1rem;
    padding-top: 10px;
  }
}
