@import "src/styles/variables";
@import "src/styles/mixin";

.vendor-details-container {
  // padding: 20px;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;

  .link-css {
    @include link-style;
  }
  .back-icon {
    font-size: 12px;
  }

  .tabs-back-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 18px;
    background-color: $white;

    .tabs-data {
      display: flex;

      .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
        height: 65px !important;
        color: black !important;
        border: 1px solid #e5e5e5;
        letter-spacing: 0.034em;
      }

      .mar-2 {
        margin-left: 2px;
      }
      .tab-label {
        display: flex;
        align-items: center;
        font-size: 12px;
        font-weight: 900;

        .completed {
          color: $green-color;
          margin-right: 5px;
        }

        .not-completed {
          color: darkgray;
          margin-right: 5px;
        }
      }
    }
  }

  .button-css {
    background-color: white !important;
    margin-bottom: 5px;
  }

  .form-section {
    border: $border;
    border-radius: 5px;
    background-color: $white;
  }
}
