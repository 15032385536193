body {
  margin: 0;
  font-family: "Nunito Sans", sans-serif !important;
  font-weight: 500 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #e6e6e6;
  padding: 0;
}

input,
select,
span,
h1,
h2,
div,
h3,
h5 {
  font-family: "Nunito Sans", sans-serif !important;
}

@font-face {
  font-family: "Nunito Sans";
  src: local("Nunito Sans"),
    url(./fonts/Nunito_Sans/NunitoSans-VariableFont_YTLC_opsz_wdth_wght.ttf)
      format("opentype");
}
